import { Component, OnInit } from '@angular/core';

import { SingletonService } from '../../../app/sipf-service/singleton.service'

declare const $: any;
declare const jquery: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './app-sidebar.component.html',
    providers: [SingletonService]
})

export class AppSidebarComponent implements OnInit {

    Menu = [];
    MenuString: string;

    constructor(
        private _service: SingletonService
    ) { }


    ngOnInit() {



        this.Menu = [];
        if (this._service.getCurrentRoleName() != "EMPLOYEE")
            this.BindMenu(this._service.getCurrentRole())
    }

    BindMenu(USER_ROLE: string) {
        if (USER_ROLE != null) {
            $.AdminOreo.loader.blockUI({ boxed: true });
            this._service.postRequest(null, 'MenuRole/RoleBaseMenu?roleid=' + USER_ROLE, 'SELECT').subscribe(
                x => {
                    $.AdminOreo.loader.unblockUI();
                    if (x.success) {
                        if (x.data == null) {
                            $.AdminOreo.alert.message_error("Record Not Found.", "");
                        }
                        else {
                            this.Menu = x.data.Menus;
                            sessionStorage.setItem("PMenus", JSON.stringify(x.data.Menus));
                            // this.MenuString =   x.data.MenuString;
                            this.MenuString = x.data.MenuString.replace(/'/g, '"');
                            sessionStorage.setItem("Menus", JSON.stringify(x.data.ListMenuItem));

                        }
                    }
                    else {
                        $.AdminOreo.alert.message_error("There is some error", "");
                    }
                    err => {
                        $.AdminOreo.loader.unblockUI();
                        // Log errors if any
                        console.log(err);
                    }
                });
        }
    }

    onRoutingAvoid() {

    }
}