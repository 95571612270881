import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import * as SIPF_STRING from './global/common.model'
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
    {
        path: 'application',
        loadChildren: 'app/application/application.module#ApplicationModule'
    },
    {
        path: 'dashboard',
        loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
    },
    {
        path: 'employee',
        loadChildren: 'app/employee/employee.module#EmployeeModule'
    },
    {
        path: 'gpf',
        loadChildren: 'app/gpf/gpf.module#GpfModule'
    },
    {
        path: 'master',
        loadChildren: 'app/master/master.module#MasterModule'
    },
    {
        path: 'si',
        loadChildren: 'app/si/si.module#SiModule'
    },
    {
        path: 'nps',
        loadChildren: 'app/nps/nps.module#NpsModule'
    },
    {
        path: 'account-re-open',
        loadChildren: 'app/account-re-open/account-re-open.module#AccountReOpenModule'
    },
    {
        path: 'helpdesk',
        loadChildren: 'app/helpdesk/helpdesk.module#HelpdeskModule'
    },
    // {
    //     path: 'mpr',
    //     loadChildren: 'app/mpr/mpr.module#MprModule'
    // },
    {
        path: 'emitra-application',
        loadChildren: 'app/emitra-application/emitra-application.module#EmitraApplicationModule'
    },
    {
        path: 'user-management',
        loadChildren: 'app/user-management/user-management.module#UserManagementModule'
    },
    {
        path: 'authentication',
        loadChildren: 'app/authentication/authentication.module#AuthenticationModule'
    },
    {
        path: 'sample-pages',
        loadChildren: 'app/sample-pages/sample-pages.module#SamplePagesModule'
    },
    {
        path: 'misc',
        loadChildren: 'app/misc/misc.module#MiscModule'
    },
    {
        path: 'reports',
        loadChildren: 'app/reports/reports.module#ReportsModule'
    },
    {
        path: 'mis-reports',
        loadChildren: 'app/mis-reports/mis-reports.module#MisReportsModule'
    },
    {
        path: 'gpa',
        loadChildren: 'app/gpa/gpa.module#GPAModule'
    },
    {
        path: 'gif',
        loadChildren: 'app/gif/gif.module#GifModule'
    },
    {
        path: 'utility',
        loadChildren: 'app/utility/utility.module#UtilityModule'
    },
    {
        path: 'payment',
        loadChildren: 'app/payment/payment.module#PaymentModule'
    },
    {
        path: 'recons',
        loadChildren: 'app/recons/recons.module#ReconsModule'
    },
    {
        path: 'process',
        loadChildren: 'app/process/process.module#ProcessModule'
    },
    {
        path: 'pages',
        loadChildren: 'app/pages/pages.module#PagesModule'
    },
    //{ path: '**', redirectTo: 'pages/Page404' },
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full'
    },

    {path: '404', component: NotFoundComponent},
    {path: '**', redirectTo: '/404'}

];

@NgModule({
    // imports: [RouterModule.forRoot(routes)],
    // exports: [RouterModule]
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        enableTracing: false
      })],
      exports: [RouterModule]

})
export class AppRoutingModule {

    

    constructor(private router: Router) {
        this.router.errorHandler = (error: any) => {
            
            
            
            //location.href=  SIPF_STRING.WEB_URL + "/pages/Page404";

            console.log(error);
            //  location.href = "404.aspx";
            //this.router.navigate(['/pages/Page404']); // or redirect to default route
        }
    }
}
