import { Component, OnInit } from '@angular/core';
import { Jsonp } from '@angular/http';

import { SingletonService } from '../../../app/sipf-service/singleton.service'

declare const $: any;
declare const jquery: any;



@Component({
    selector: 'role-ddl',
    templateUrl: './app-roleddl.component.html',
})

export class AppRoleddlComponent implements OnInit {

    RolesDDL = [];

   CurrentRole: String;

    constructor(
        private _service: SingletonService
    ) { } 

    ngOnInit() {

        this.CurrentRole = this._service.getCurrentRole();
        this.RolesDDL = this._service.getRoles();
         
        if(this._service.getEMPID()!=undefined){
        this.BindRole(this._service.getEMPID());
        }
        
    }
    
    BindRole(USER_NAME: string) {
        $.AdminOreo.loader.blockUI({ boxed: true });
    
        var request = { "PUSER": USER_NAME };
        this._service.postRequest(null, 'Dashboard/SearchRole/' + USER_NAME, 'SELECT').subscribe(
          x => {
            $.AdminOreo.loader.unblockUI();
            if (x.success) {
              if (x.data == null) {
                $.AdminOreo.alert.message_error("Record Not Found.", "");
              }
              else {
               
                this.RolesDDL = JSON.parse(JSON.stringify(x.data));
                // sessionStorage.setItem("roles", JSON.stringify(x.data));
                // sessionStorage.setItem("current-role", x.data[0].TB_TRN_USER_ROLE_MAPID);
                // sessionStorage.setItem("current-role-name", x.data[0].ROLE_NAME);
                // sessionStorage.setItem("current-role-dashboard", x.data[0].DASHBOARD_NAME.toLowerCase());
                //$(location.href = 'employee/dashboard');
    
                //this.result = x.data;
              }
            }
            else {
              $.AdminOreo.alert.message_error("There is some error", "");
            }
            err => {
              $.AdminOreo.loader.unblockUI();
              // Log errors if any
              console.log(err);
            }
          });
      }


    changeRole(obj : any ) {
       
       sessionStorage.setItem("current-role-Id", obj.TB_MST_ROLEID);
        sessionStorage.setItem("current-role", obj.TB_TRN_USER_ROLE_MAPID);
        sessionStorage.setItem("current-role-name", obj.ROLE_NAME);
        sessionStorage.setItem("current-role-dashboard", obj.DASHBOARD_NAME.toLowerCase());
        sessionStorage.setItem("role-details", btoa(JSON.stringify(obj)));


        let activityLogPayload = {
          "EMPLOYEE_ID": this._service.getEMPID(),
          "CATEGORY": "ROLE_SWITCH",
          "SUB_CATEGORY": "SIPF_ROLE_SWITCH",
          "ACTIVITY_MESSAGE": "employee switched role",
          "TB_TRN_USER_ROLE_MAPID": obj.TB_TRN_USER_ROLE_MAPID
        }

        this._service.postRequest(activityLogPayload, 'Auth/EmployeeActivityLog', null).subscribe();
        if (obj.ROLE_NAME != "EMPLOYEE")
        {
          
            sessionStorage.setItem("show_menu", "true");
           // sessionStorage.setItem("show_search", "true");
              if(obj.IS_SEARCH == "true")
              {
                sessionStorage.setItem("show_search", "true");
              }
              else 
              {
                sessionStorage.setItem("show_search", "false");
              }
        }
        else
        {
            sessionStorage.setItem("show_menu", "false");
            sessionStorage.setItem("show_search", "false");
        }
        
        if (obj.ROLE_NAME == "EMPLOYEE") {
            $(location.href = "/"+ obj.DASHBOARD_NAME.toLowerCase());
        }
      //   if (ROLE_NAME == "HELP DESK") {
      //     $(location.href = "/helpdesk/helpdesk-listfilter");
      // }
        else {
            $(location.href = "/" + obj.DASHBOARD_NAME.toLowerCase());
        }
    }
}

