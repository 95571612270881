import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppHeaderComponent } from '../common/components/app-header/app-header.component'
import { AppSearchHeaderComponent } from '../common/components/app_search/app-search.component'
import { AppSidebarComponent } from '../common/components/app_sidebar/app-sidebar.component'
import { AppRoleddlComponent } from '../common/components/app_roleddl/app-roleddl.component'

//import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

 
import {
  MatSelectModule,
  MatDatepickerModule,
  MatIconModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatSlideToggleModule, 
  MatCheckboxModule,
  MatRadioModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatMenuModule
  
} from '@angular/material';
import { NotFoundComponent } from './not-found/not-found.component';
import { AmountToWordPipe } from './amount-to-word.pipe';


@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppSearchHeaderComponent,
    AppSidebarComponent,
    AppRoleddlComponent,
    NotFoundComponent
  ],
  imports: [
    HttpClientModule,
   // HttpModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatSelectModule,
    MatPaginatorModule,    
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    MatSortModule,
    MatSlideToggleModule, 
    MatCheckboxModule,  
    MatToolbarModule,
    Ng2SearchPipeModule,    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSortModule,
    MatRadioModule,
    MatSlideToggleModule, 
    MatMenuModule,
    MatCheckboxModule,
    MatToolbarModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {


}
