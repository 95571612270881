import { Component, OnInit, Renderer2, enableProdMode } from '@angular/core';
enableProdMode();
import { NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { ClsSession } from '../app/shared/cls-session.model'
import { SingletonService } from '../app/sipf-service/singleton.service'
import { ClsDropdownModel } from './shared/cls-dropdown-model';
import { MatSlideToggleChange } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const jquery: any;
declare const screenfull: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [SingletonService],

})
export class AppComponent implements OnInit {
    previousUrl: string;
    objClfy: string;
    isLoggedIn: boolean = false;
    isShowButton: boolean = false;
    isLoggedInEmitra: boolean = false;
    Fy_year: string;
    params_SelfOrOtherEmp: string;
    KIOSKCODE: string;
    checkLoginOTPToggle : boolean = false;
    showOTPDisableSetting : boolean = false;
    employeeId : string;
    showLanguageOptions = false;
    selectedLanguage: string;  

    constructor(private renderer: Renderer2, private router: Router, private _service: SingletonService, private routeS: Router
        , private route: ActivatedRoute, 
        private translate: TranslateService ) {
       
        let displayLangFromSession = sessionStorage.getItem("DISPLAY_LANG") || 'en'; 
        this.translate.setDefaultLang(displayLangFromSession); // Set default language
        this.translate.use(displayLangFromSession); // Use a specific
        this.selectedLanguage = displayLangFromSession;  

        // window.onbeforeunload = function(e) {

        //     $.AdminOreo.alert.message_error("", "This operation is not allowed.", function () {
        //         sessionStorage.clear();
        //         e.preventDefault();
        //         this.routeS.navigate(["/authentication/sign-in"]); 
        //       });

        //   };


        this.router.events

            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    if (this.previousUrl) {
                        this.renderer.removeClass(document.body, this.previousUrl);
                    }
                    const currentUrl = event.url.split('/');
                    const currentUrlSlug = currentUrl[currentUrl.length - 1];
                    if (currentUrlSlug) {
                        if (currentUrlSlug == 'e-mitra') {
                            this.isLoggedIn = false;
                            this.isLoggedInEmitra = true;
                        }
                        else {

                            this.renderer.addClass(document.body, currentUrlSlug);
                        }
                    }
                    this.previousUrl = currentUrlSlug;
                }
            });

        this.isLoggedIn = _service.isLoggedIn();
    }

    logout = function () {
        this.activityLog();
        this.logOut()
        $.ajax({
            type: 'POST',
            url: 'https://sso.rajasthan.gov.in/signout',
            data: {
                'userdetails': sessionStorage.getItem("SSO_token")
            },
            success: function (msg) {
                alert(msg);
            }
        });

        sessionStorage.clear();

        $(location.href = 'https://sso.rajasthan.gov.in');

        //this.router.navigate(['/authentication/sign-in']);
    }

    helpdesk(Url: string) {        
        this.routeS.navigate(['/' + Url.toLowerCase() + '/helpdesk-list/']);
    };


    ngOnInit() {  

        let otpVerficationStatus = sessionStorage.getItem("otp_verified");    
        // let decryptData = this._service.decryptData(otpVerficationStatus);
        // let decryptData = atob(otpVerficationStatus);
        if(sessionStorage.getItem("employee_id")        
         && otpVerficationStatus == null  
        ){        
            sessionStorage.clear();
            window.location.href = 'https://sso.rajasthan.gov.in/sso'
        }       


        this.isShowButton = this._service.isShowSearch();
        this.KIOSKCODE = this._service.get_KIOSKCODE();
        this.employeeId  = this._service.getEMPID()
        this.route.params.subscribe(routeParams => {
            this.params_SelfOrOtherEmp = this._service.decryptData(routeParams['id']);
            // In a real app: dispatch action to load the details here.
        });

        let rolesData = JSON.parse(sessionStorage.getItem("roles"));
        if(rolesData && rolesData.length > 1) {
            this.showOTPDisableSetting = true
        }

        //this.getotpToggleStatus()
        this.Fy_year = this._service.getfyyear();
        // Set Theme
        $("body").removeClass("theme-purple")
            .removeClass("theme-blue").
            removeClass("theme-cyan").
            removeClass("theme-green").
            removeClass("theme-orange").
            removeClass("theme-blush").
            removeClass("menu_dark");

        if (sessionStorage.getItem("theme") != "") {
            $("body").addClass(sessionStorage.getItem("theme"));
            $("body").addClass(sessionStorage.getItem("theme-menu"));
        }
        else {
            $("body").addClass("theme-purple");
        }

        // ========       
        if (!this._service.isShowMenu()) {
            $("body").addClass("ls-toggle-menu");
        }


        setTimeout(function () {
            menuToggle();
            $.each($('.menu .list li.active'), function (i, val) {
                const $activeAnchors = $(val).find('a:eq(0)');
                $activeAnchors.addClass('toggled');
                $activeAnchors.next().show();
            });

            $('.ml-menu li a').on('click', function () {
                $(this).addClass('toggled');
            });
            $('.sidebar .menu .list').slimscroll({
                height: 'calc(100vh - 65px)',
                color: 'rgba(0,0,0,0.2)',
                position: 'left',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            $('.ls-toggle-btn').on('click', function () {
                $('body').toggleClass('ls-toggle-menu');
            });

            // Set Theme

        }, 1000);

        function menuToggle() {
            // Collapse or Expand Menu
            $('.menu-toggle').on('click', function (e) {
                const $this = $(this);
                const $content = $this.next();

                if ($($this.parents('ul')[0]).hasClass('list')) {
                    const $not = $(e.target).hasClass('menu-toggle') ? e.target : $(e.target).parents('.menu-toggle');
                    $.each($('.menu-toggle.toggled').not($not).next(), function (i, val) {
                        if ($(val).is(':visible')) {
                            $(val).prev().toggleClass('toggled');
                            $(val).slideUp();
                        }
                    });
                }

                $this.toggleClass('toggled');
                $content.slideToggle(320);
            });
        }

    }

    switchLanguage(lang: string) {
        sessionStorage.setItem("DISPLAY_LANG", lang)
        this.translate.use(lang);
    }

    search_employee = function () {
        //window.open(this._service.getAPI_URL() + '/reports/employee-detail');
        this.routeS.navigate(["/reports/employee-detail"]);
    }

    getotpToggleStatus() {
        let queryString = this.employeeId + "&caseType=SELECT&IS_LOGIN_OTP_ENABLED=" ;
        this._service.postRequest(null, 'Employee/LoginOTPsettingStatus?EMPLOYEEID=' + queryString, null).subscribe(
          apiResponse => {
            $.AdminOreo.loader.unblockUI();
            if (apiResponse.success) {
              if(apiResponse.data.ListorItems && apiResponse.data.ListorItems.length === 0 ) {
                this.checkLoginOTPToggle = true;
                // let rolesData = JSON.parse(sessionStorage.getItem("roles"));
                // if(rolesData.length > 1) {
                //   this.checkLoginOTPToggle = false;
                // }
              } else if (apiResponse.data.ListorItems && apiResponse.data.ListorItems.length > 0 ) {
                this.checkLoginOTPToggle = (apiResponse.data.ListorItems[0].IS_LOGIN_OTP_ENABLED == "Y") ? true : false;
              }
            }
            else {
              $.AdminOreo.alert.message_error("There is some error", "");
            }
            err => {
              $.AdminOreo.loader.unblockUI();
              // Log errors if any
              console.log(err);
            }
          });
      }
    
      slideToggle(event: MatSlideToggleChange)
       {
        $.AdminOreo.loader.blockUI({ boxed: true });
        let checkLoginOTPToggle  = (event.checked) ? "Y" : "N"; 
        let queryString = this.employeeId + "&IS_LOGIN_OTP_ENABLED="+checkLoginOTPToggle +"&caseType=INSERT_OR_UPDATE";
        this._service.postRequest(null, 'Employee/LoginOTPsettingStatus?EMPLOYEEID=' + queryString, 'INSERT').subscribe(
          x => {
            $.AdminOreo.loader.unblockUI();
            if (x.success) {
                $.AdminOreo.alert.message_success("", x.message);
              }
            else {
              $.AdminOreo.alert.message_error("There is some error", "");
            }
            err => {
              $.AdminOreo.loader.unblockUI();
              // Log errors if any
              console.log(err);
            }
          });
      }
    
/**
     * TODO : we should user sso official api insted of doing this.
     */

    backToSSO() {
        this.activityLog();
        this.logOut();
        sessionStorage.clear();
        window.location.href = 'https://sso.rajasthan.gov.in/sso'
    }

    activityLog() {
        let activityLogPayload = {
            "EMPLOYEE_ID": this._service.getEMPID(),
            "CATEGORY": "LOGOUT",
            "SUB_CATEGORY": "SIPF_LOGOUT",
            "ACTIVITY_MESSAGE": "employee logged out from sipf portal",
            "TB_TRN_USER_ROLE_MAPID": null
        }
  
        this._service.postRequest(activityLogPayload, 'Auth/EmployeeActivityLog', null).subscribe();

    }

    logOut() {
        let logOutPayload = { "ID": this._service.getEMPID(), 
                                   "TokenID": this._service.getToken(), 
                                   "RoleID": "" }
  
        this._service.postRequest(logOutPayload, 'Auth/LogoutauthSSO', null).subscribe();
    }



}
