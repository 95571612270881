import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SingletonService } from '../../../app/sipf-service/singleton.service'

import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { NavigationStart,ActivatedRoute, Router, RouterLink } from '@angular/router'

import { ClsSession } from '../../../app/shared/cls-session.model'
declare const $: any;
declare const jquery: any;

@Component({
    selector: 'app-search-header',
    templateUrl: './app-search.component.html',
    providers: [SingletonService]
})

export class AppSearchHeaderComponent implements OnInit {

    inputobj: Object = {};
    result: ClsSession = null;
    hideResult: boolean = false;
    booleanIS_GPF: boolean = false;
    booleanIS_NPS: boolean = false;
    EmployeeSearchForm: FormGroup;
    submittedES = false;
    isShowSearchBar: boolean = false;
    V_SERVICE_STATUS: string = '100000001';
    constructor(  
        private routeS: Router, 
        private _service: SingletonService,
        private SchemeArrayDetails: FormBuilder,) {
    }

    ngOnInit() {
        
    if (!this._service.isShowSearch()) {
        this.isShowSearchBar = false;
    }
    else
    {
        this.isShowSearchBar = true;
       
    }
    

    this.EmployeeSearchForm = this.SchemeArrayDetails.group({
        EMPLOYEECODE: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{16}$')]],
      });
  
    }
    
    get ES() { return this.EmployeeSearchForm.controls; }
    onSubmitSearch() {
       
        if(this.EmployeeSearchForm.invalid)
        {
            $.AdminOreo.alert.message_info( "","16 digit Alpha-Numeric Employee ID is required.");
            return;
        }
        else
        {
       
        $.AdminOreo.loader.blockUI({ boxed: true });
        this._service.postRequest(null, 'Auth/SearchUser/'+this.EmployeeSearchForm.value.EMPLOYEECODE, 'INSERT').subscribe(
            x => {
                $.AdminOreo.loader.unblockUI();
                if (x.success) {
                    if (x.data == null) {
                        $.AdminOreo.alert.message_info("","Employee ID : "+this.EmployeeSearchForm.value.EMPLOYEECODE+" not found in system." );
                        this.hideResult = false;
                    }
                    else 
                    {
                        
                        this.hideResult = true;
                        this.result = x.data;
                        this.V_SERVICE_STATUS = this.result.SERVICE_STATUS;
                        if(this.result.IS_GPF_NPS == 'GPF')
                        {
                            this.booleanIS_GPF = true;
                            this.booleanIS_NPS = false;
                            
                        }
                        else if(this.result.IS_GPF_NPS == 'NPS')
                        {
                            this.booleanIS_GPF = false;
                            this.booleanIS_NPS = true;
                        }
                    }
                }
                else {
                    $.AdminOreo.alert.message_error("There is some error", "");
                }
                err => {
                    $.AdminOreo.loader.unblockUI();
                }
            });
        }
    }


onRoutingDashboard(url:string)
{
    this.hideResult = false;

    $(".morphsearch-close").click();
    
    if(url == "/employee/dashboard/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/1"]);
    }
    else if(url == "/si/dashboard/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/2"]);
    } 
    else if(url == "/gpf/dashboard/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/3"]);
    } 
    else if(url == "/si/editcontract/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/4"]);
    } 
    else if(url == "/si/loan-adjustment/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/5"]);
    } 
    else if(url == "/nps/dashboard/")
    {
        this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)+"/1/6"]);
    } 
}

onRoutingApplyApp(url:string,param1:string,param2:string)
{
    this.hideResult = false;
    $(".morphsearch-close").click();

    if(url == "/gpf/claim/")
    {
    this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE) +"/2/1/"+this._service.encryptData(param2)+"/"+this._service.encryptData(param1) ]);
    }
    else if(url == "/si/first-declaration/")
    {
    this.routeS.navigate(["/employee/redirect/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE) +"/2/2/"+this._service.encryptData(param2)+"/"+ this._service.encryptData(param1)]);
    } 
    else if(url == "/account-re-open/employee-account-re-open/")
    {
    this.routeS.navigate(["/account-re-open/employee-account-re-open/"+this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)]);
    } 

    // if(url == "/gpf/claim/")
    // {
    // this.routeS.navigate([this._service.encryptData(param1)+"/2/1/"+this._service.encryptData(param2)+"/"+ this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)]);
    // }
    // else if(url == "/si/first-declaration/")
    // {
    // this.routeS.navigate([this._service.encryptData(param1)+"/2/2/"+this._service.encryptData(param2)+"/"+ this._service.encryptData(this.EmployeeSearchForm.value.EMPLOYEECODE)]);
    // } 
   
}
}

